import React from "react";

// images
// no images

function TypographySection() {
  return (
    <section className="text-left mt-20 w-full" id="typography">
      <div className="bg-second_color sticky top-0">
        <h1 className="heading-1" tabIndex="0">
          typography
        </h1>
      </div>
      <p
        className="font-second_font font-light text-2xl my-4 md:w-5/6"
        tabIndex="0"
      >
        ‘DINosaur’ is our brand font and should be used as often as possible.
        This font is clean and modern, while friendly and approachable. Subtle
        soft edges give the font a slight humanist warmth. We use the Light,
        Book, and Medium font weights only. Avoid using the font too big and too
        bold.
      </p>

      <h2 className="heading-2" tabIndex="0">
        primary font
      </h2>
      <figure className="leading-none">
        <p className="font-primary_font text-primary_color bg-s4 text-6xl md:text-10xl tracking-tighter pl-8 pt-8 pb-8 mt-1 uppercase">
          display headline.
        </p>
        <figcaption className="font-second_font font-light text-sm mt-2">
          DISPLAY HEADLINE, DINosaur LIGHT, 120PT, UPPERCASE
        </figcaption>
      </figure>
      <figure className="leading-none">
        <p className="font-primary_font text-primary_color bg-s4 text-4xl tracking-tighter pl-8 pt-8 pb-8 mt-12">
          Sentence Case.
        </p>
        <figcaption className="font-second_font font-light text-sm mt-2">
          SECONDARY HEADLINES, DINosaur BOOK, 42PT, SENTENCE CASE
        </figcaption>
      </figure>

      <figure className="leading-tight my-4 pt-4">
        <p className="leading-normal font-second_font bg-s4 text-lg font-light md:pr-48 p-8 my-2">
          Paragraph text, gravida mi nibh, a auctor enim tempor non. Sed non
          ultrices neque. Nunc lectus lorem, condimentum vitae sem sit amet,
          lobortis ullamcorper nisi. Nam odio lorem, varius vel lacus id,
          ullamcorper suscipit arcu. Praesent vel diam nec augue porttitor
          molestie. Nam sed justo ac est sodales hendrerit quis nec eros. Mauris
          non massa vitae nunc tincidunt egestas. Vivamus enim nulla,
          pellentesque id pharetra sed, lacinia at nulla. Maecenas nec suscipit
          libero. Nunc tempor nulla eu urna tempor, ut pharetra nulla pretium.
        </p>
        <figcaption className="font-second_font font-light text-sm">
          PARAGRAPH TEXT, DINosaur BOOK, 18PT, SENTENCE CASE
        </figcaption>
      </figure>
      <div className="flex flex-col md:flex-row h-16 mt-16 mb-32 md:mb-0">
        <a
          href={`/downloads/ARB_Typography-Specs.pdf`}
          className="no-underline"
          download
        >
          <button className="btn" id="download-logo-mark">
            download
          </button>
        </a>
        <p className="my-4 md:ml-8 inline-block align-middle">
          Download typography sample and spec sheet PDF.
        </p>
      </div>
      <div className="flex flex-col md:flex-row h-16 mt-8 mb-2 md:mb-0">
        <a
          href={`https://fonts.adobe.com/fonts/dinosaur`}
          className="no-underline"
          rel="noopener noreferrer"
          target="_blank"
        >
          <button className="btn" id="download-logo-mark">
            download
          </button>
        </a>
        <p className="my-4 md:ml-8 inline-block align-middle">
          Download our Adobe Font for print and digital use.
        </p>
      </div>
    </section>
  );
}

export default TypographySection;
