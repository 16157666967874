import React from "react";

// images:
import visualAttitude from "../images/ARB_VISUAL-ATTITUDE.jpg";

/* jshint ignore:start */
function ConceptSection() {
  return (
    <section className="text-left" id="concept">
      <div className="bg-second_color sticky top-0">
        <h1 className="heading-1" tabIndex="0">
          concept
        </h1>
      </div>
      <p className="my-4 md:w-5/6" tabIndex="0">
        Our brand concept reflects on everything from our logo to our color
        palette and typography. We often refer back to this strategy when making
        brand decisions.
      </p>
      <h2 className="heading-2" tabIndex="0">
        mantra
      </h2>
      <p className="text-lg my-4 md:w-5/6" tabIndex="0">
        Our brand mantra is not advertising copy or a tagline, and, in most
        cases, it won’t be something we use publicly at all. This is who we are,
        our brand essence and our brand promise.
      </p>
      <p
        className="font-primary_font text-primary_color bg-s4 p-8 md:pr-20 -ml-1 my-4"
        tabIndex="0"
      >
        We believe quality food, friendly service, and a vibrant atmosphere
        should be available to everyone. Located in the heart of Hayes Valley,
        we’re the quintessential destination for our local community either here
        or on-the-go. With a variety of fast healthy food, beer and wine on tap,
        or a bottle from our connected wine shop, it’s your choice. We let
        people curate their own experience at Arbor—sit outside in our casual
        garden patio or enjoy a variety of indoor options for yourself or with
        friends. We believe people deserve good food + good service.
      </p>
      <h2 className="heading-2" tabIndex="0">
        personality
      </h2>
      <p className="text-lg my-4 md:w-5/6" tabIndex="0">
        Our personality is an important part of our brand because, like human
        personality, it is both differentiating and enduring. Our brand
        personality directs the voice, tone and style through which we
        communicate.
      </p>
      <div className="font-primary_font text-primary_color grid grid-flow-col grid-cols-2 grid-rows-6 bg-s4 pl-8 pt-8 pb-8 md:pr-20 -ml-1 my-4">
        <p className="" tabIndex="0">
          quality
        </p>
        <p className="" tabIndex="0">
          service
        </p>
        <p className="" tabIndex="0">
          casual
        </p>
        <p className="" tabIndex="0">
          healthy
        </p>
        <p className="" tabIndex="0">
          comfortable
        </p>
        <p className="" tabIndex="0">
          inclusive
        </p>
        <p className="" tabIndex="0">
          vibrant
        </p>
        <p className="" tabIndex="0">
          friendly
        </p>
        <p className="" tabIndex="0">
          simple
        </p>
        <p className="" tabIndex="0">
          curated
        </p>
        <p className="" tabIndex="0">
          approachable
        </p>
      </div>
      <h2 className="heading-2" tabIndex="0">
        visual attitude
      </h2>
      <p className="text-lg my-4" tabIndex="0">
        This visual brand attitude is a summation of our mantra and personality.
        Like the mantra, it is for internal use and evokes the attitude we want
        people to feel when associating with our brand. Many of our personality
        words can be found in this mood board, from casual and comfortable,
        friendly and approachable, to inclusive and vibrant.
      </p>
      <figure className="">
        <img
          alt="mosaic of images showing arborsf visual attitude"
          className="block mx-auto w-full"
          src={visualAttitude}
        />
        <figcaption className="font-second_font font-light text-sm uppercase"></figcaption>
      </figure>
    </section>
  );
}
/* jshint ignore:end */
export default ConceptSection;
