import React from "react";

// images
import photography1 from "../images/ARB_PHOTOGRAPHY_1.jpg";
import photography2 from "../images/ARB_PHOTOGRAPHY_2.jpg";
import photography3 from "../images/ARB_PHOTOGRAPHY_3.jpg";
import photography4 from "../images/ARB_PHOTOGRAPHY_4.jpg";
import photography5 from "../images/ARB_PHOTOGRAPHY_5.jpg";
import photography6 from "../images/ARB_PHOTOGRAPHY_6.jpg";

function PhotographySection() {
  return (
    <section className="text-left mt-20 w-full" id="photography">
      <div className="bg-second_color sticky top-0">
        <h1 className="heading-1" tabIndex="0">
          photography
        </h1>
      </div>
      <p className="font-second_font font-light  text-2xl my-4 md:w-5/6" tabIndex="0">
        Our photography should always align with our brand personality. All photos should be: 
        simple &amp; curated, casual &amp; comfortable, approachable &amp; friendly.
      </p>

      <h2 className="heading-2 my-6" tabIndex="0">
          photography art direction
      </h2>
      <figure className="">
        <img
            alt="photography image placeholder"
            className="block mb-1 mx-auto w-full"
            src={photography1}
          />
        <figcaption className="font-second_font font-light text-sm uppercase">
          Venue: Capture details like natural wood, wicker caning, greenery, organic textures.</figcaption>
      </figure>
      <div className="grid grid-cols-2 gap-8 my-8">
        <figure className="">
          <img
              alt="photography image placeholder"
              className="block mb-1 mx-auto w-full"
              src={photography2}
            />
          <figcaption className="font-second_font font-light text-sm uppercase">
            Always inclusive of age, gender, ethnicity.</figcaption>
        </figure>
        <figure className="">
          <img
              alt="photography image placeholder"
              className="block mb-1 mx-auto w-full"
              src={photography3}
            />
          <figcaption className="font-second_font font-light text-sm uppercase">
            Clean & polished, natural & organic.</figcaption>
        </figure>
      </div>
      <div className="grid grid-cols-3 gap-8 mb-4">
        <figure className="">
          <img
              alt="photography image placeholder"
              className="block mb-1 mx-auto w-full"
              src={photography4}
            />
          <figcaption className="font-second_font font-light text-sm uppercase">
            Soft focus backgrounds.</figcaption>
        </figure>
        <figure className="">
          <img
              alt="photography image placeholder"
              className="block mb-1 mx-auto w-full"
              src={photography5}
            />
          <figcaption className="font-second_font font-light text-sm uppercase">
            Authentic, never posed.</figcaption>
        </figure>
        <figure className="">
          <img
              alt="photography image placeholder"
              className="block mx-auto w-full"
              src={photography6}
            />
          <figcaption className="font-second_font font-light text-sm uppercase">
            Capture natural setting.</figcaption>
        </figure>
      </div>
    </section>
  );
}

export default PhotographySection;