import React from "react";

// images
import illustration1 from "../images/ARB_ILLUSTRATION_1.jpg";
import illustration2 from "../images/ARB_ILLUSTRATION_2.jpg";
import illustration3 from "../images/ARB_ILLUSTRATION_3.jpg";

function PhotographySection() {
  return (
    <section className="text-left mt-20 w-full" id="illustration">
      <div className="bg-second_color sticky top-0">
        <h1 className="heading-1" tabIndex="0">
          illustration
        </h1>
      </div>
      <p
        className="font-second_font font-light  text-2xl my-4 md:w-5/6"
        tabIndex="0"
      >
        Created with the same shapes from the letterforms of our logo, our brand
        pattern adds a hint of textural warmth when needed. The pattern emulates
        the wicker caning found inside Arbor, bringing a familiar interior
        element to our printed and digital collateral.
      </p>

      <figure className="">
        <img
          alt="photography image placeholder"
          className="block mb-1 mx-auto w-full"
          src={illustration1}
        />
        <figcaption className="font-second_font font-light text-sm uppercase">
          Pattern should only be used as a subtle background texture.
        </figcaption>
      </figure>
      <div className="grid grid-cols-2 gap-8 my-8">
        <figure className="">
          <img
            alt="photography image placeholder"
            className="block mb-1 mx-auto w-full"
            src={illustration2}
          />
          <figcaption className="font-second_font font-light text-sm uppercase">
            Pattern Color: C:0 M:10 Y:45 K:0
          </figcaption>
        </figure>
        <figure className="">
          <img
            alt="photography image placeholder"
            className="block mb-1 mx-auto w-full"
            src={illustration3}
          />
          <figcaption className="font-second_font font-light text-sm uppercase">
            Example pattern usage.
          </figcaption>
        </figure>
      </div>
      <div className="flex flex-col md:flex-row h-16 mt-16 mb-1">
        <a
          href={`/downloads/ARBOR_Pattern.eps`}
          className="no-underline"
          download
        >
          <button className="btn" id="download-logo-mark">
            download
          </button>
        </a>
        <p className="my-4 md:ml-8 inline-block align-middle">
          Download the vector pattern for use.
        </p>
      </div>
    </section>
  );
}

export default PhotographySection;
